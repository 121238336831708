<template>
  <div class="">
    <div id="components-form-demo-advanced-search">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="角色名称：">
              <a-input
                v-model="roleName"
                placeholder="请输入角色名称进行查询"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="创建时间：">
              <a-range-picker
                :value="pickerValue"
                @change="onChange"
                :placeholder="['开始时间', '结束时间']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">
              查询
            </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              清除
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div class="table">
        <a-row>
          <a-col :span="24" :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit" @click="toCreate()">
              新建
            </a-button>
          </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" style="margin-top:10px">
          <a slot="name" slot-scope="text">{{ text }}</a>
          <span slot="status">
            <a-switch default-checked />
          </span>
          <span slot="opera" slot-scope="text, record">
            <a-tag
              slot="opera"
              color="green"
              @click="toShow(record)"
              style="cursor: pointer;"
            >
              查看
            </a-tag>
            <a-tag
              slot="opera"
              color="green"
              @click="toEdit(record)"
              style="cursor: pointer;"
            >
              编辑
            </a-tag>
            <a-tag
              slot="opera"
              color="volcano"
              @click="deleteData(record)"
              style="cursor: pointer;"
            >
              删除
            </a-tag>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 删除弹框 -->
    <div>
      <a-modal
        title="提示"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        ok-text="确认"
        cancel-text="取消"
      >
        <p>{{ ModalText }}</p>
      </a-modal>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "序号 ",
    dataIndex: "no",
    key: "no",
    width: 50
  },
  {
    title: "角色名称",
    dataIndex: "name",
    key: "name",
    width: 150
  },
  {
    title: "角色描述",
    dataIndex: "describe",
    key: "describe",
    width: 150
  },
  {
    title: "角色权限",
    dataIndex: "auth",
    key: "auth"
  },
  {
    title: "创建时间",
    key: "time",
    dataIndex: "time",
    width: 160
  },
  {
    title: "操作",
    key: "opera",
    dataIndex: "opera",
    width: 180,
    scopedSlots: { customRender: "opera" }
  }
];

const data = [
  // {
  //   key: "1",
  //   no: 1,
  //   name: "超级管理员",
  //   describe: "呃呃呃",
  //   auth: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "2",
  //   no: 2,
  //   name: "John Brown",
  //   describe: "呃呃呃",
  //   auth: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "3",
  //   no: 3,
  //   name: "John Brown",
  //   describe: "呃呃呃",
  //   auth: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "4",
  //   no: 4,
  //   name: "John Brown",
  //   describe: "呃呃呃",
  //   auth: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // }
];
import { getRoleList, deleteRole } from "@/api/permission/role/index";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      data,
      columns,
      ModalText: "确定要删除该角色吗？",
      visible: false,
      confirmLoading: false,

      pickerValue: [],

      roleName: "",
      beginTime: "",
      endTime: ""
    };
  },
  computed: {},
  created() {
    this.data = [];
    getRoleList({
      role_name: this.roleName,
      begin_time: this.beginTime,
      end_time: this.endTime
    }).then(res => {
      console.log(res);
      for (let i in res.rows) {
        let data = res.rows[i];
        let auth = "";
        for (let i in data.menus) {
          auth +=
            data.menus[i].menu_name + (i == data.menus.length - 1 ? "" : ",");
        }
        this.data.push({
          key: i,
          id: data.dbId,
          no: parseInt(i) + 1,
          name: data.role_name,
          describe: data.remark,
          auth: auth,
          time: data.create_time,
          opera: ["查看", "编辑", "删除"]
        });
      }
    });
  },
  updated() {
    console.log("updated");
  },
  methods: {
    getList() {
      this.data = [];
      getRoleList({
        role_name: this.roleName,
        begin_time: this.beginTime,
        end_time: this.endTime
      }).then(res => {
        console.log(res);
        for (let i in res.rows) {
          let data = res.rows[i];
          this.data.push({
            key: i,
            id: data.dbId,
            no: 1,
            name: data.role_name,
            describe: data.remark,
            auth: data.remark,
            time: data.create_time,
            opera: ["查看", "编辑", "删除"]
          });
        }
      });
    },
    // 搜索
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
      this.getList();
    },
    // 日期选择框
    onChange(date, dateString) {
      this.pickerValue = date;
      this.beginTime = dateString[0];
      this.endTime = dateString[1];
      // console.log(date);
      // console.log(dateString);
    },
    //清除
    handleReset() {
      this.pickerValue = [];
      this.form.resetFields();
      this.beginTime = "";
      this.endTime = "";
      this.roleName = "";
      this.getList();
    },
    //打开删除弹框
    deleteData(value) {
      console.log(value);
      this.id = value.id;
      this.visible = true;
    },
    //确定删除
    handleOk() {
      this.visible = false;
      let _this = this;
      deleteRole({
        roleId: _this.id
      })
        .then(res => {
          console.log(res);
          if (res.code == 200) {
            _this.$message.success("删除成功", function() {
              _this.getList();
            });
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch(err => {
          _this.$message.error(err.message);
        });
    },
    //取消删除
    handleCancel() {
      this.visible = false;
    },
    //新建/编辑/查看
    toCreate() {
      this.$router.push({
        path: "/index/auth/role/createRole"
      });
    }, //新建/编辑/查看
    toEdit(value) {
      console.log(value);
      this.id = value.id;
      this.$router.push({
        path: "/index/auth/role/createRole",
        query: {
          id: this.id
        }
      });
    }, //新建/编辑/查看
    toShow(value) {
      console.log(1);
      this.id = value.id;
      this.$router.push({
        path: "/index/auth/role/createRole",
        query: {
          id: this.id,
          show: 1
        }
      });
    }
  }
};
</script>
<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #ffffff;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 6px; */
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #ffffff;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.table {
  margin-top: 20px;
  background: #ffffff;
  padding: 20px;
}
</style>
