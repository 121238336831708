import { render, staticRenderFns } from "./roleList.vue?vue&type=template&id=78193094&scoped=true&"
import script from "./roleList.vue?vue&type=script&lang=js&"
export * from "./roleList.vue?vue&type=script&lang=js&"
import style0 from "./roleList.vue?vue&type=style&index=0&id=78193094&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78193094",
  null
  
)

export default component.exports