import http from "@/plugins/http";
//获取列表
export function getRoleList(data) {
  return http({
    url: `/system/role/list`,
    method: "GET",
    params:data,
  });
}

// 新增
export function createRole(data) {
  console.log(data);
  return http({
    url: `/system/role`,
    data: data,
    method: "POST"
  });
}

// 详情
export function showRole(data) {
  console.log(data);
  return http({
    url: `/system/role/${data.roleId}`,
    method: 'GET',
  })
}

// 编辑
export function editRole(data) {
  console.log(data);
  return http({
    url: `/system/role/`,
    data:data,
    method: 'PUT',
  })
}

// 删除
export function deleteRole(data) {
  console.log(data);
  return http({
    url: `/system/role/${data.roleId}`,
    method: "DELETE"
  });
}

